import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const Ekaterinburg = new Album(
  new Text('Ekaterinburg. The Picture of City'),
  Genre.Street,
  new Date('2014-12-25'),
  new Photograph('/photographs/2014-12-21_25_city/2014-12-21_064_large.webp'),
  [
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_001_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_002_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_003_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_004_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_005_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_006_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_007_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_008_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_009_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_010_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_011_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_012_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_013_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_014_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_015_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_016_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_017_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_018_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_019_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_020_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_021_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_022_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_023_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_024_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_025_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_026_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_027_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_028_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_029_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_030_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_031_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_032_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_033_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_034_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_035_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_036_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_037_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_038_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_039_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_040_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_041_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_042_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_043_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_044_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_045_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_046_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_047_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_048_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_049_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_050_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_051_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_052_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_053_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_054_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_055_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_056_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_057_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_058_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_059_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_060_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_061_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_062_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_063_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_064_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_065_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_066_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_067_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_068_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_069_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_070_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_071_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_072_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_073_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_074_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_075_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_076_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_077_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_078_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_079_large.webp'),
    new Photograph('/photographs/2014-12-21_25_city/2014-12-21_080_large.webp'),
  ]);

export default Ekaterinburg;