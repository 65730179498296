import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const RussianEmirates = new Album(
  new Text('Russian Emirates'),
  Genre.Street,
  new Date('2012-11-21'),
  new Photograph('/photographs/2012-11-09_21/2012-11-09_21_090_large.webp'),
  [
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_001_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_002_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_003_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_004_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_005_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_006_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_007_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_008_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_009_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_010_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_011_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_012_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_013_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_014_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_015_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_016_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_017_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_018_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_019_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_020_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_021_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_022_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_023_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_024_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_025_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_026_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_027_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_028_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_029_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_030_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_031_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_032_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_033_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_034_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_035_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_036_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_037_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_038_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_039_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_040_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_041_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_042_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_043_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_044_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_045_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_046_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_047_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_048_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_049_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_050_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_051_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_052_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_053_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_054_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_055_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_056_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_057_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_058_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_059_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_060_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_061_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_062_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_063_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_064_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_065_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_066_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_067_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_068_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_069_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_070_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_071_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_072_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_073_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_074_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_075_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_076_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_077_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_078_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_079_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_080_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_081_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_082_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_083_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_084_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_085_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_086_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_087_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_088_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_089_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_090_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_091_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_092_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_093_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_094_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_095_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_096_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_097_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_098_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_099_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_100_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_101_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_102_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_103_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_104_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_105_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_106_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_107_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_108_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_109_large.webp', true),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_110_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_111_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_112_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_113_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_114_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_115_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_116_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_117_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_118_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_119_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_120_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_121_large.webp'),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_122_large.webp'),
  ]);

export default RussianEmirates;