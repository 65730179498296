import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Video from '../core/video';
import Text from '../core/text';
import Genre from '../core/genre';

const LakeBaikal = new Album(
  new Text('Lake Baikal'),
  Genre.Nature,
  new Date('2015-08-08'),
  new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_035_large.webp', false, new Text('The mouth of the Bolshoy Chivyrkuy river - the first morning on the shore of the lake.')),
  [
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_001_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_002_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_003_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_004_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_005_large.webp', false, new Text('Siberian dwarf pine on the slopes of the Barguzinsky Range.\n\nPinus pumila, commonly known as the Siberian dwarf pine, dwarf Siberian pine is a tree in the family Pinaceae native to northeastern Asia and the Japanese isles. It shares the common name creeping pine with several other plants.\n\nThe Siberian dwarf pine is a coniferous evergreen shrub ranging from 1–3 m in height, exceptionally up to 5 m, but may have individual branches that extend farther along the ground in length. In the mountains of northern Japan, it sometimes hybridises with the related Japanese white pine (Pinus parviflora); these hybrids (Pinus × hakkodensis) are larger than P. pumila, reaching 8–10 m tall on occasion.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_006_large.webp', false, new Text('Barguzin Range is a range along the northeastern shore of Baikal.\n\nIts length is 280 km, height up to 2,840 m. It is mostly covered by larch taiga. The range bounds the Barguzin Valley on the northwest. A part of the Barguzin Nature Reserve is located on the western slopes of the range. ')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_007_large.webp', true, new Text('Gremyachaya River. The evening sunset on the second day of the trip.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_008_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_009_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_010_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_011_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_012_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_013_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_014_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_015_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_016_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_017_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_018_large.webp', true),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_019_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_020_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_021_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_022_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_023_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_024_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_025_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_026_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_027_large.webp', true),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_028_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_029_large.webp', true),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_030_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_031_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_032_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_033_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_034_large.webp', true),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_035_large.webp', false, new Text('The mouth of the Bolshoy Chivyrkuy river - the first morning on the shore of the lake.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_036_large.webp', false, new Text('During the hike, no matter where I was - on the top of high mountains, in the middle of impenetrable forests, or on the popular beach, I met tourists from all over the world. I was greatly impressed by their fearlessness and love of adventure. Some of the tourists were traveling alone — I met a French gentleman and a young lady from Moscow. I was alarmed that both lacked any understanding of the principles of surviving in the wild and their complete defenselessness against possible dangers.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_037_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_038_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_039_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_040_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_041_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_042_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_043_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_044_large.webp', false, new Text('Zmeevaya Bay is known for its thermal and healing waters. The bay got its name from the non-venomous snakes - the eastern racer living in this area.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_045_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_046_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_047_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_048_large.webp', false, new Text('Sunset on the shore of Lake Baikal.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_049_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_050_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_051_large.webp', true, new Text('The day before arriving in the bay, the bear tore the fishermen\'s tent to shreds. Fortunately, the fishermen were on the voyage at that moment. Forewarned of what happened, we set up a night guard and a sound alarm.\n\nTo our great surprise, on the evening, almost immediately after sunset, a young one and a half-year-old bear easily bypassed the alarm, going exactly along the perimeter of the installation. This is how my first close encounter with a wild bear took place. He walked at a distance of five meters from me and with an imperturbable gaze went further into the forest.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_052_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_053_large.webp', false, new Text('The forest and mountains are shrouded in thick smoke from raging fires. In those days, we saw animals fleeing from the fires.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_054_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_055_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_056_large.webp', false, new Text('Svyatoy Nos or Holy Nose is a large peninsula on the eastern edge of Lake Baikal, Eastern Siberia, in the Barguzinsky District of the Republic of Buryatia. It is part of the Zabaykalsky (Trans-Baikal) National Park.\n\nThe name "Svyatoy Nos" means "Holy Nose" in Russian. Russian explorers in the 17th and 18th centuries used the word "nose" to mean "cape". "Holy Nose" originally referred to the southwestern cape of the peninsula, and a (now abandoned) village on that cape. Then the name passed on the whole territory of the peninsula. In the Buryat language, the peninsula is called Hilmen Hushun, which means "sturgeon\'s muzzle."')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_057_large.webp', false, new Text(`A paw of a young bear and a palm of a man.\n\nThe brown bear occurs in all ranges surrounding Baikal. Approximately twenty thousand animals live in a territory of about sixty thousand square kilometers. The greatest densities occur on the western slope of the Bargyzinski Range between the Malaya Cheremshava and Shirildi Rivers. All the areas have a low human population, extensive mountain taiga, an abundance of food, and a prohibition on hunting for bears.`)),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_058_large.webp', false, new Text('The Holy Nose Peninsula is on fire. Because of strong thunderstorms, since the end of July, violent fires have been raging in the mountains in the Baikal nature reserves. About 25,000 hectares of forest were affected by the fire.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_059_large.webp', false, new Text('Martian sunset on the shore of Lake Baikal.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_060_large.webp', false, new Text('Morning breakfast on the shore of Lake Baikal. An hour later, the rescue team evacuated our group away from the raging fires.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_061_large.webp', false, new Text('Bolshoe Alginskoe Lake belongs to the group of soda-salt lakes of the Barguzin Valley.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_062_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_063_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_064_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_065_large.webp', false, new Text('Suva Saxon Castle\n\nOne of the main attractions of the Barguzin Valley. Picturesque cliffs resembling the ruins of an ancient castle, with stone towers and a narrow rocky gorge, located on the steppe slopes at the foot of the Ikat ridge.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_066_large.webp', false, new Text('The Bukhe Shulun stone is a shrine of the Barguzin Valley - the spirit of the bull is considered the master of the entire valley. An ancient legend says that when the Buryats settled in the valley, they drove cattle to the Chita lands for the winter. One bull fell in love with the valley and did not want to leave it. Three times bull was forcibly taken away from the valley, but each time he came back until he turned into stone.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_067_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_068_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_069_large.webp', true, new Text('The Barguzinsky Datsan is a Buddhist temple located at the foot of the mountains, on the border of the Kuytun steppes of the Barguzin Valley.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_070_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_071_large.webp', false, new Text('Turtle Rock is a natural geological sculpture, whose shape is reminiscent of a turtle (an animal venerated by Buddhists) with stretched neck, located on the northern part of Turka valley.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_072_large.webp'),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_073_large.webp'),
    new Video('https://www.youtube.com/embed/VlXZW_dqlUE?rel=0', new Text('The video report of the exploring expedition to Lake Baikal.')),
  ]);

export default LakeBaikal;