import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const ImperialTown = new Album(
  new Text('Imperial Town'),
  Genre.Street,
  new Date('2012-05-09'),
  new Photograph('/photographs/2012-05-05_09/2012-05-05_09_016_large.webp'),
  [
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_001_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_002_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_003_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_004_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_005_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_006_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_007_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_008_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_009_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_010_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_011_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_012_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_013_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_014_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_015_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_016_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_017_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_018_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_019_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_020_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_021_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_022_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_023_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_024_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_025_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_026_large.webp', true),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_027_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_028_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_029_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_030_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_031_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_032_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_033_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_034_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_035_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_036_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_037_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_038_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_039_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_040_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_041_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_042_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_043_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_044_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_045_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_046_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_047_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_048_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_059_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_050_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_051_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_052_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_053_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_054_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_055_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_056_large.webp'),
    new Photograph('/photographs/2012-05-05_09/2012-05-05_09_057_large.webp'),
  ]);

export default ImperialTown;