import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const CastilloDeMataplana = new Album(
  new Text('Castillo de Mataplana'),
  Genre.Nature,
  new Date('2022-07-24'),
  new Photograph('/photographs/2022-07-23_24/2022-07-23_24_008.webp'),
  [
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_001.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_002.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_003.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_004.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_005.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_006.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_007.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_008.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_009.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_010.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_011.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_012.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_013.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_014.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_015.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_016.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_017.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_018.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_019.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_020.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_021.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_022.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_023.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_024.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_025.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_026.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_027.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_028.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_029.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_030.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_031.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_032.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_033.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_034.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_035.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_036.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_037.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_038.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_039.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_040.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_041.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_042.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_043.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_044.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_045.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_046.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_047.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_048.webp'),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_049.webp', true),
    new Photograph('/photographs/2022-07-23_24/2022-07-23_24_050.webp'),
  ]);

export default  CastilloDeMataplana;