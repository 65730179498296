import Album from './../../models/photographs/imperialTown';
import Headline from './../shared/Headline';
import Layout from './../shared/Layout';
import Photograph from '../shared/Photograph';

const ImperialTown = () => {
  return (
    <Layout title = { Album.getTitle().getValue() }>
      <div className = 'container'>
        <Headline album = { Album } locale = { 'en' } />

        <p>Огромная улица большого города по сторонам которой стоят высокие здания. Многие украшены барельефами исторических фигур, персонажей легенд цивилизаций прошлого и рекламными вывесками развлекательных центров и магазинов. Среди архитектурных ансамблей проносится многотысячная толпа способная смести неуклюжего бедолагу, жаждущего поднять выроненный из рук драгоценный предмет. Сквозь этот хаос пробираются двое молодых людей, - уставших, еле передвигающих ноги, но все еще находящих силы добраться до дома - так, в два часа после полудня, начался первый день, двух удивительно разных и непреклонно гордых своим мнением, во встречающим их палящим солнцем городе.</p>
        <p>Их путешествие началось днем ранее, когда они встретились в родном для Кати городе - так зовут юную спутницу, давшую согласие поехать в интересный для нее город с человеком, которого она видела лишь раз до этого времени. Она бесконечно влюблена в столичный город и любая мысль об отъезде приносит ей боль, но тем не менее, в погоне за неизвестным, ей хватило храбрости покинуть знакомые улицы. Каждое лето, на протяжении годов, она ездит с родителями на Кубань, лишая себя дюжины друзей и помпезности мегаполиса, о возвращении в который не забывает и на минуту. Эта поездка для нее первая, когда ей удалось покинуть город самостоятельно, взяв ответственность за свою жизнь в собственные руки.</p>

        <Photograph album = { Album } index = { 0 } />

        <p>Город манил своими красотами, и не было ни единой причины выжидать времени в номере отеля. Расположение временного пристанища подсказывало первую достопримечательность для посещения - Александро-Невская Лавра, стоящая на том месте, где в 1240 году войска Александра Невского одержали победу над шведами в ставшей легендарной Невской битве. Сейчас здесь монастырь, построенный по приказу Петра I и кладбища, в землях которых лежат тела выдающихся гениев - Глинки, Гумилева, Достоевского, Ломоносова, Римского-Корсакова, Суворова, Чайковского и многих других, в числе которых, разумеется есть и мощи самого Александра.</p>

        <Photograph album = { Album } index = { 1 } />

        <p>Для большинства, лавра - действующий памятник, где продолжаются службы, но роль которого ясна любому, пожелавшему войти в стены храма - заработок денег. Что, в общем и понятно - нужно поддерживать состояние исторического ансамбля. И туристов здесь не мало - много немцев, приезжих русских, американцев, французов и остальных, чей говор, то и дело, проносится мимо. Хватает здесь и попрошаек, просящих милостыню, с каждым разом, выдумывая новую причину не начать работать.</p>
        <p>Перейдя Неву, путешественники направились к Таврическому саду, разбитому при строительстве дворца фельдмаршала Потемкина-Таврического. Роскошный парк, с чистыми озерами, в водах которых плавают утки, вековыми дубами, стоящими у окраин с самого зарождения памятника, чисто убранными дорожками - они положили начало теплой атмосфере уюта, в объятиях которого собрались петербуржцы - влюбленные пары ищущие романтики, родители, вышедшие на прогулку с малышами, пожилые люди, медленно пересекающие парк, и молодежь, распевающая песни под гитару.</p>

        <Photograph album = { Album } index = { 2 } />

        <p>За отведенные на поездку четыре дня требовалось посетить как можно больше достопримечательностей, познакомившись с городом так близко, чтобы почувствовать своим нутром его мышление. За Таврическим садом, по Шпалерной улице стоит Смольный монастырь, заложенный наследницей императорского престола Елизаветой Петровной, решившей провести последние годы жизни в тишине и покое монастыря, в окружении ста двадцати благородных девиц. Здесь же, неподалеку, стоит увековеченное в истории Здание Смольного, резиденция губернатора, известная своей ролью в Октябрьской революции.</p>

        <Photograph album = { Album } index = { 5 } />

        <p>Изрядно нагулявшись путники возвращались в отель, где их ждал ужин и первые сновидения, за время которых сменится погода, пожелавшая очистить прекрасный город перед грядущими празднованиями и массовыми гуляниями. Так подошли к концу первые сутки, навсегда вошедшие в память гостеприимством города и безмятежной жизнью юных сердец.</p>
        <p className = 'text-center'>...</p>
        <p>Проснувшись после длиной ночи, убаюкивающей ритмичными ударами капель весеннего дождя, в самые ранние часы, которых шел счет в борьбе за каждую минуту, спутники двинулись к центру города, где их ожидали самые желанные предметы обозрения. Выбрав обходные пути, где по берегам Грибоедова канала каждый может отыскать сокровенные таинства императорского города, на Сенной площади начался второй день странствования. Завлеченные красным куполом пятьдесят седьмого дома на Садовой их маршрут шел по воспетым поэтами тропам.</p>

        <Photograph album = { Album } index = { 8 } />

        <p>Каждый новый шаг таил сюрпризы, требующие найти ответ на смышленую загадку, оставленную неизвестным автором.</p>

        <Photograph album = { Album } index = { 13 } />

        <p>Наконец они подошли к подножию Исаакиевского Собора, крупнейшего православного храма Санкт-Петербурга, освященного во имя святого Исаакия Далматского, в день почитания которого увидел свет Петр Великий.</p>

        <Photograph album = { Album } index = { 14 } />

        <p>Сегодня собор имеет статус государственного музея, в стенах которого, в определенные, разрешенные руководством, дни, проводятся богослужения. Расположенный между зданиями Сената и Адмиралтейства он привлекает толпы туристов, которым предлагается возможность посетить как сам собор, с его великолепным интерьером, выполненным из мрамора и украшенный малахитом, лазуритом, золоченной бронзой и невероятной красоты мозаикой, так и колоннаду, откуда с высоты птичьего полета открывается вид на всю центральную часть города.</p>

        <Photograph album = { Album } index = { 15 } />
        <Photograph album = { Album } index = { 16 } />

        <p>Построенный в 1818-1856 годах французским архитектором Огюстом Монферраном, чей бюст, созданный из камней, использовавшихся при строительстве, установлен в музее, памятник архитектуры времен начала Российской Империи олицетворяет уже четвертую инкарнация собора, чье рождение было положено Петром.</p>

        <Photograph album = { Album } index = { 18 } />
        <Photograph album = { Album } index = { 20 } />
        <Photograph album = { Album } index = { 21 } />

        <p>Стрелка часов продолжала свой неустанный ход и подошло время отправиться к следующей достопримечательности - Зимнему Дворцу, официальной резиденции российских императоров. Многократно перестроенный он стоит на берегу Невы с 1762 года, демонстрируя изысканность и мощь российской государственности. Само здание, внутреннее устройство и отделку перепланировали с приходом входящих на престол императоров и сменой государственного устройства. И по сей день, входящий в перечень мирового наследия, Зимний вынужден терпеть вторжения в его прекрасный облик.</p>

        <Photograph album = { Album } index = { 25 } />

        <p>По ту сторону Дворцового моста, среди Ростральных колонн с их аллегорическими фигурами у подножья, олицетворяющими три великих русских реки, сигнальными огнями, выполнявшими роль маяков,  и здания Биржи напротив, на стрелке Васильевского острова, продолжалось любознательное приключение. Солнце не торопилось согреть его лучами, а ветер Невы поспевал отобрать все то тепло, что сохраняла земля после ушедшего дня - погода портилась, становясь все холоднее, отбирая последние надежды согреться.</p>
        <p>Дело шло к полудню и город оживал нарастающим автомобильным движением и туристами, заполнявшими площади. Становилось не протолкнуться и на восточной конечности острова собирался легион фотографов и их моделей - школьная экскурсия, напоминающая рой муравьев, пары влюбленных и туристов, горожане, они все с восторгом напоминали о их существовании. И все же, здесь, между Зимним и Петропавловской крепостью, чувствовалась поразительная энергетика, скопившаяся за многовековую историю Петровского окна.</p>

        <Photograph album = { Album } index = { 26 } />

        <p>У стен крепости приземлилось винтокрылое воздушное судно некой важной персоны, приковавшей к себе многочисленные взгляды наблюдателей. У подножья собора, немецкие девушки, видимо ошарашенные и никогда не видавшие голубей, разгоняла усталых птиц безумными криками с непристойной манерой, свойственной маленьким детям.</p>
        <p>Наконец, налюбовавшись достопримечательностями, путники подошли к подземному метро, отправившись на Невский Проспект, возвратившись в точку начала их путешествия. Здесь их ждало удивительное зрелище, поначалу напугавшее своими громкими звуками, но после восхитившее красотой - колонна мотоциклистов проносилась по Невскому проспекту, занимая всю правую сторону движения, - они ехали к Адмиралтейству. Среди них были мотоциклисты на самой разной технике, - от легких спортивных байков, до дорогих Дукати и Харли-Девидсонов.</p>

        <Photograph album = { Album } index = { 29 } />

        <p>Вечер обещал быть длинным, переходя в удивительную ночную экскурсией по городу в неоновом свете. Идя по спрятанным от живущей активной жизнью города улочкам, тихо продолжающим свое существование в спокойном и равнодушном к повседневным незадачам течении, вниманию глаз предстал преобразившийся город с плавной, ритмичной городской жизнью, с диковинно украшенными зданиями и словно остановившимися на минуту, оглядываясь по сторонам, памятниками.</p>

        <Photograph album = { Album } index = { 33 } />

        <p>Так, незнакомая тропа, привела к соборам, сначала, Воскресения Христова, сооруженного в память смертельного покушения на императора Александра II, и после Казанскому иконы Божией Матери, крупнейшему храму Петербурга, приобретшего значение памятника русской воинской славы по итогам Отечественной войны 1812, и в стенах которых похоронено тело полководца Кутузова, одержавшего сокрушительную победу над Наполеоном.</p>
        <p className = 'text-center'>...</p>
        <p>С наступлением ночи город погрузился во мрак, в пору разведенных мостов и неоновых огоньков, освещающих золоченные барельефы дворцов. Этим временем, в начале одиннадцатого часа, началась увлекательная экскурсия, охватившая весь город, с его лучшими достопримечательностями, ранее не известными, ни Кате, ни Андрею, едущими на соседних креслах, спасающего от морозной питерской ночи, автобуса. Экскурсовод, женщина шестидесяти лет, видимо историк, не спеша вела свой рассказ о проносящемся за окнами архитектурном наследии.</p>
        <p>Осмотрев почти весь город и подъехав к берегу Невы на Университетской набережной, взору путешественников предстали сфинксы. Возрастом в три с половиной тысячи лет, сооруженные в Египте для фараона Аменхотепа III, они бережно охраняют свои настоящие владения с 1832 года.</p>
        <p>"Да живет Гор, Могучий телец, воссиявший в правде, установивший законы, успокоивший обе земли. Золотой Гор, телец царей, покоритель девяти луков. Царь Верхнего и Нижнего Египта, владыка обеих земель Небмаатра, отпрыск Ра. Сын Ра, любимый его Аменхотеп - властитель Фив, образ Ра перед обоими Землями. Гор - добрый владыка вечности, которому даны жизнь, постоянство, счастье, здоровье" - гласят надписи, представляющие полное титульное имя фараона Аменхотепа III, воплощенного бога на земле.</p>

        <Photograph album = { Album } index = { 38 } />

        <p>Вскоре экскурсия продолжилась путешествием по рекам и каналам Петербурга. Удивительная возможность посмотреть на город глазами его создателя, видевшего свое творение северной Венецией, разделенной сетью водных дорог. В час ночи границы города меняют свои очертания разделяя мегаполис на отдельные острова с разведенными между ними мостами. Участники речной прогулки с удивлением ждали этого момента, всматриваясь в угасающее движение на дорогах. Наконец , один за другим, мосты поднялись, освободив дорогу груженным баржам, выстроившимся в длинную очередь.</p>

        <Photograph album = { Album } index = { 39 } />

        <p>Не имея желания помешать их движению экскурсионный корабль отправился по Фантанке вверх, посетив пристанище юркого Чижика. После путешественники пересели вновь в свой автобус, оставленный возле Дворцовой площади, и продолжили путь, объезжая город.</p>
        <p className = 'text-center'>...</p>
        <p>Расположенный на южном берегу Финского залива он манит своей удивительной красотой. Петергоф, основанный в 1710 как императорская загородная резиденция, не может оставить равнодушным и самого сухого привередливостью критика. Большой дворец - центр ансамблей Нижнего парка и Верхнего сада, морской канал протекающий среди аллеи фонтанов, крохотные декоративные дворцы, залив и сотни других, впечатляющих конструкций оставленных в наследие величайшими правителями России.</p>

        <Photograph album = { Album } index = { 43 } />

        <p>Еще не успевшие покрыться листвой деревья парков придавали чувство преждевременности события, его скорого свершения. В садах, пробегающие белки, привыкшие к людскому вниманию, привлекали его требуя лакомств. Многочисленные школьники и взрослые туристы осадили территорию испытывая спрятанные тайники, готовые облить освежающей струей воды прошедших возле них бедолаг.</p>
        <p>Так, под лучами яркого слепящего солнца, начинался этот день. Он изменит многое между двумя путниками, - они уже вскоре после полудня, когда возвратятся в город, расставят по своим углам взвешенную недосказанность, но все же оставив вопросы открытыми.</p>
        <p>Вечер прошел у дворцовой площади, где собрав дюжины молодых людей, историческую реконструкцию из техники середины ушедшего столетия, артистов и любознательных туристов, он создавал предпраздничную атмосферу подготовки к завтрашнему параду Великой Победы, торжественно приветствуя гостеприимством. В какой то момент настало ощущение близости с городом, - это переход, в который все окружающее становилось знакомым, будто было известно с самого детства.</p>

        <Photograph album = { Album } index = { 50 } />

        <p className = 'text-center'>...</p>
        <p>Настал заключительный день этого очаровывающего путешествия и сегодня он будет ничем не похожим на предыдущие. В эту дату в России празднуют победу в Великой Отечественной, поминая отдавших ради нее свою жизнь и проводя военные парады в городах необъятной родины. Питер не станет исключением, собрав на Дворцовой площади десятки тысяч, жаждущих помпезного марша, зрителей. По своей зрелищности питерский парад серьезно уступает проводимому в столице. Здесь пройдут немногочисленные колонны моряков и армейских войск, колонны исторической и современной техники, в составе ракетных комплексов и бронетранспортеров.</p>

        <Photograph album = { Album } index = { 53 } />

        <p>Кордоны полицейских оцепили дворцовую, тысячи горожан идут по Невскому, обходя перекрытый подход к Адмиралтейству, к Зимнему дворцу. Каждый ищет себе удобное место, всеми силами разыскивая любую возвышенность, способную предоставить пусть малое, но преимущество над окружающей массой. Гражданские, военные, ветераны и дети, - были все. Не мало пришло иностранцев, так удивленных русской манере праздновать трагедию миллионов. И все же происходящее объединяло всех и каждого.</p>

        <Photograph album = { Album } index = { 55 } />

        <p>Оставляя о себе радостные воспоминания, последние часы прибывания в городе уходили с каждым моментом. Полдень пройдет на набережной лейтенанта Шмидта и у стен церкви Смоленской иконы Божией Матери, окруженной одноименным кладбищем. Уже под самый занавес, за которым будет долгая ночь на железной дороге, начнется вечерний парад, идущий с окраин города. Одна из колонн начала свое движение у Московского вокзала, где с первыми шагами парадного марша, в свой путь ушел обратный поезд, увозивший домой память о прошедших питерских каникулах.</p>
      </div>
    </Layout>
  );
}

export default ImperialTown;