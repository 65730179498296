import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const Altay = new Album(
  new Text('Altay. Conquering Siberia 2.0'),
  Genre.Nature,
  new Date('2020-08-24'),
  new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_100.webp'),
  [
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_002.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_003.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_004.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_005.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_006.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_008.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_010.webp', false, new Text('Mountain Devils Thumb')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_011.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_013.webp', false, new Text('Katun River')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_014.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_015.webp', false, new Text('Puchina Lake')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_019.webp', false, new Text('Chemal Hydroelectric Power Station')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_020.webp', false, new Text('Monastery in Patmos')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_022.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_024.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_026.webp', false, new Text('Ininsky Suspension Bridge')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_027.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_029.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_030.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_031.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_032.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_033.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_034.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_035.webp', false, new Text('The Confluence of Chuya and Katun Rivers')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_036.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_037.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_038.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_039.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_040.webp', false, new Text('Petroglyphs in Kalbak-Tash')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_041.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_042.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_043.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_044.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_045.webp', false, new Text('Blue Geyser Lake')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_046.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_047.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_048.webp', true),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_049.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_050.webp', false, new Text('The road to Mount Aktru')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_051.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_052.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_053.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_054.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_055.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_056.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_057.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_058.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_062.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_063.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_064.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_065.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_067.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_068.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_069.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_070.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_072.webp', false, new Text('Mountain the Foreheads of Rams')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_080.webp', false, new Text('Great Aktru Glacier')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_081.webp', true, new Text('Blue Lake')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_082.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_083.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_085.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_087.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_088.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_092.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_095.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_096.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_097.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_098.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_099.webp', false, new Text('Great Aktru Glacier')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_100.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_106.webp', true, new Text('Small Aktru Glacier')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_107.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_108.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_111.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_112.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_113.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_115.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_116.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_117.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_118.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_119.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_121.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_122.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_125.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_126.webp', false, new Text('Kosh-Agach')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_127.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_128.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_129.webp', false, new Text('Martian Landscapes. Mountains of Kyzyl-Chin.')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_130.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_131.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_132.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_134.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_135.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_136.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_138.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_139.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_140.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_141.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_142.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_143.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_144.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_145.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_146.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_147.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_149.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_150.webp', true, new Text('Kurai Steppe')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_152.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_153.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_154.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_155.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_156.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_157.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_160.webp', false, new Text('Aktash')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_161.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_162.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_164.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_165.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_166.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_167.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_168.webp', false, new Text('Aktash Repeater')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_169.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_170.webp', true, new Text('Waterfall Shirlak')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_171.webp', true),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_172.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_173.webp', false, new Text('Bom Kur-Kechu')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_174.webp', false, new Text('Chike-Taman Pass')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_176.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_177.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_178.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_179.webp', false, new Text('Karakol')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_180.webp'),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_182.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_183.webp', false, new Text('Ursul River')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_184.webp', false, new Text('Karakol')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_189.webp', false, new Text('Petroglyphs in Karakol')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_190.webp', false, new Text('Graves in Karakol')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_191.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_192.webp', false, new Text('Gorno-Altaysk\n\nThe capital town of the Altai Republic. The selo of Ulala was established in 1824 when migrants moved to this area from Biysk. The mountains located within the town\'s boundaries are a magnet for skiing as well as backpacking.')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_193.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_194.webp'),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_196.webp', false, new Text('Tavdinsky Caves')),
    // new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_199.webp', true),
  ]);

export default Altay;