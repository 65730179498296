import Album from './../../models/photographs/russianEmirates';
import Headline from './../shared/Headline';
import Layout from './../shared/Layout';
import Photograph from '../shared/Photograph';

const RussianEmirates = () => {
  return (
    <Layout title = { Album.getTitle().getValue() }>
      <div className = 'container'>
        <Headline album = { Album } locale = { 'en' } />

        <p>Скоростной поезд, ничем не выдававший своего ошеломительного движения, бесшумно мчался среди высотных зданий, впечатляющих талантом своих создателей. Восходящее солнце обводило силуэты архитектурных шедевров и городского пейзажа, уносящегося далеко за горизонт. Широкая магистраль, рассекающая спящий мегаполис, несла рой, проносящихся двенадцатью полосами, автомобилей. Такими останутся в воспоминаниях первые минуты, в открывавшем восточный мир, Дубае.</p>

        <Photograph album = { Album } index = { 78 } />

        <p>Начало этому путешествию было положено в первые месяцы года, когда детали, тщательнейшим образом продуманного, плана, лишь обретали свои первые черты. Перебирая десятки вариантов, выбор был сделан в пользу Эмиратов, удивительной страны, возведённой посреди пустыни, где за четыре прошедших десятилетия обрели свой дом развитое арабское государство, сохранившее культуру, и мировой финансовый центр, приведший миллионы европейцев.</p>

        <p>Подготовка к поездке пусть и заняла время, но оно было потрачено исключительно на изучение страны. Вся рутина, сводившаяся к бронированию отеля, покупки билетов и оформлению визы, причём все операции были совершенны через Интернет, заняла короткие трое суток, ушедших, по большей части, на ожидание ответа консульства.</p>

        <p>Многие предупреждали о закрытости государства, строгости местных законов и нетерпимости к некоторым атрибутам европейского быта. К счастью, предупреждения оказались раздутым мифом, никак не сочетающимся с положением дел. Разумеется, приезжая в мусульманское государство, нужно иметь в виду культурные различия, но и делать из мухи слона, нет никакого смысла. Напротив, как показали, проведённые в стране десять дней, гостеприимные арабы всегда открыты к разговору и рады прийти на помощь при первой необходимости.</p>

        <h2>Дубай</h2>

        <p>Окна отеля выходили на безмятежно ровную гладь лазурного моря. В стороне показывалась «Пальма», объединяющая множество искусственно нанесённых островов, с десятками дорогих отелей и частных домов миллионеров, отдавших предпочтение изысканным апартаментам. Береговая линия усеяна десятками ресторанов и магазинов, за которыми стоят, выстроенные в четыре ряда, дюжины, рассекающих облака, здания, скрывающие своей грозной тенью жаркое солнце персидского залива. </p>

        <Photograph album = { Album } index = { 14 } />
        <Photograph album = { Album } index = { 15 } />
        <Photograph album = { Album } index = { 21 } />
        <Photograph album = { Album } index = { 23 } />

        <p>Как и пустынный цветок, раскрывающий свои лепестки с заходом солнца, Дубай расцветает в ночи, когда каждая улица оживает в свете сверкающих фонарей и фар уносящихся автомобилей.</p>

        <Photograph album = { Album } index = { 119 } />
        <Photograph album = { Album } index = { 11 } />
        <Photograph album = { Album } index = { 113 } />
        <Photograph album = { Album } index = { 14 } />

        <p>Словно сошедший со страниц сказок Шахиризады, Дубай олицетворяет самые грандиозные мечты. Воплощённые в жизнь они влекут своим очарованием, в котором нет никакой обыденности европейских мегаполисов и повседневности жизни, для многих протекающей между непримечательной квартирой, за центром города, и офисом.</p>

        <Photograph album = { Album } index = { 54 } />
        <Photograph album = { Album } index = { 53 } />

        <p>Стремясь к вершинам Олимпа, Дубай побивает рекорды и устанавливает новые планки достижений человеческого гения. Самое высокое здание мира, Бурдж-Халифа, уходящее к облакам на небывалые восемьсот двадцать восемь метров, откуда со смотровой площадки, расположенной на сто двадцать четвёртом этаже, открывается прекраснейший вид.</p>

        <Photograph album = { Album } index = { 100 } />
        <Photograph album = { Album } index = { 101 } />
        <Photograph album = { Album } index = { 102 } />
        <Photograph album = { Album } index = { 103 } />

        <p>Дубай-Молл разместивший под своей крышей свыше тысячи двухсот магазинов, десятков аттракционов, кинотеатров, аквариум и ледовый каток, он входит в шестёрку крупнейших торгово-развлекательных центров мира.</p>

        <Photograph album = { Album } index = { 1 } />
        <Photograph album = { Album } index = { 76 } />
        <Photograph album = { Album } index = { 57 } />
        <Photograph album = { Album } index = { 2 } />
        <Photograph album = { Album } index = { 6 } />
        <Photograph album = { Album } index = { 9 } />
        <Photograph album = { Album } index = { 58 } />

        <p>По соседству с Бурдж-Халифой, в водах искусственного озера, расположен чарующий своим видом поющий фонтан, достигающий высоты в сто пятьдесят метров. Его шоу, сопровождаемое лазерным преставлением и музыкой, не оставит равнодушным и самого строгого критика.</p>

        <Photograph album = { Album } index = { 0 } />
        <Photograph album = { Album } index = { 116 } />
        <Photograph album = { Album } index = { 117 } />
        <Photograph album = { Album } index = { 118 } />

        <p>В море Персидского залива, на расстоянии двухсот восьмидесяти метров от берега, стоит самый роскошный отель мира, семизвёздочный Бурдж Аль Араб, разместивший в своих стенах, двести два прекрасно декорированных двухэтажных номера.</p>

        <Photograph album = { Album } index = { 89 } />

        <p>В свете ночи, посреди стен старого города, где нашёл своё пристанище богатейший восточный рынок, в водах залива Крик начинают свой путь круизные лодки Доу, создаваемые местными кораблестроителями на протяжении многих поколений.</p>

        <Photograph album = { Album } index = { 82 } />
        <Photograph album = { Album } index = { 83 } />
        <Photograph album = { Album } index = { 84 } />

        <p>Многие века известный как город купцов, выросший из торгового порта, Дубай превратился в роскошный рай, торговый центр мирового масштаба, изобилуя ресторанами, ночными клубами и магазинами, завоевав кредо «Парижа Персидского залива».</p>

        <Photograph album = { Album } index = { 74 } />
        <Photograph album = { Album } index = { 75 } />
        <Photograph album = { Album } index = { 80 } />

        <h2>Абу-Даби</h2>

        <p>День приезда в столицу Эмиратов совпал с празднованием Нового Года, отмечаемого по лунному календарю, начавшему своё летоисчисление двадцать шестого июля шестьсот двадцать второго года от рождества Христова, когда Пророк Мухаммед был вынужден покинуть Мекку и переехать в Медину. Тогда, как и сейчас, эта дата не входит в число праздников ислама и в большинстве мусульманских стран не отмечается как праздник в светском понимании, нося при этом высокий религиозный смысл.</p>

        <p>Красивое название города, которое на русский можно перевести как «отец газели», исходит из старинной легенды, в которой газель, мчащаяся от преследования, привела, сохранивших ей жизнь, охотников к источнику с прекрасной пресной водой. Вблизи этого места и было основано поселение.</p>

        <p>Сегодня Абу-Даби это крупный мегаполис с полуторамиллионным населением, развитой экономикой, туризмом и колоссальными планами развития, устремлёнными в далёкое будущее.</p>

        <Photograph album = { Album } index = { 32 } />
        <Photograph album = { Album } index = { 31 } />

        <p>Белоснежная жемчужина Арабских Эмиратов, выполненная в мраморе с узорами из сусального золота и щедро украшенная россыпью драгоценных камней, Гранд Мечеть, носящая имя своего основателя - первого президентом ОАЭ Шейха Зайда ибн Султана ан-Нахайяна, она гордо встречает гостей именитой столицы.</p>

        <Photograph album = { Album } index = { 33 } />
        <Photograph album = { Album } index = { 34 } />
        <Photograph album = { Album } index = { 35 } />
        <Photograph album = { Album } index = { 36 } />

        <p>Величественная мечеть по праву считается одним из прекраснейших храмов, когда либо построенных человеком. Украшенная восьмьюдесятью куполами, удерживаемыми радами из свыше тысячи колон, она способна вместить сорок тысяч верующих.</p>

        <Photograph album = { Album } index = { 37 } />
        <Photograph album = { Album } index = { 38 } />
        <Photograph album = { Album } index = { 39 } />

        <p>Внутреннее убранство этого, чарующего своей сказочной красотой, архитектурного шедевра, покрыто иранским ковром, чья длина превосходит любые, когда бы то ни было пошитые полотна, и семью люстрами, изготовленными из сусального золота и кристаллов Сваровски.</p>

        <Photograph album = { Album } index = { 40 } />
        <Photograph album = { Album } index = { 41 } />
        <Photograph album = { Album } index = { 42 } />

        <h2>Аль-Аин</h2>

        <p>Посреди бескрайней пустыни, в окружении величественных скал горного массива Хаджар, вдали от ближайшего моря, где по всей видимости нет никакой возможности встретить живое существо, раскинул свои роскошные сады самый зелёный и плодородный уголок Аравийского полуострова, чарующий своим видом он расцвёл вопреки любым ожиданиям.</p>

        <p>Старинный город, возраст которого насчитывает более пяти тысяч лет, он удивляет своим богатым культурным наследием, сохранившимся до наших дней и ландшафтом, где каждый клочок плодородной земли покрыт борющимися за жизнь зеленными растениями. Райский уголок, ставший родиной правителя Эмиратов, он преображается буквально на глазах, с каждых годом становясь все краше.</p>

        <Photograph album = { Album } index = { 59 } />

        <p>Возвышающаяся на высоту более километра, гора Джебель Хафит открывает величественную панораму окрестностей, уводя взгляд далеко за край пустыни.</p>

        <Photograph album = { Album } index = { 60 } />
        <Photograph album = { Album } index = { 62 } />
        <Photograph album = { Album } index = { 63 } />

        <p>Основанный в начали семнадцатого века в честь объединения племени Джалили с шейхом Шахбутом, форт Аль Джахили, стал символом дружбы, разместив в своих стенах один из известнейших музеев Аль-Аина.</p>

        <Photograph album = { Album } index = { 64 } />
        <Photograph album = { Album } index = { 65 } />
        <Photograph album = { Album } index = { 66 } />
        <Photograph album = { Album } index = { 67 } />
        <Photograph album = { Album } index = { 68 } />
        <Photograph album = { Album } index = { 69 } />

        <p>Построенный в симметрии со своим братом близнецом, стоящим на стороне скромного Омана, города Аль-Аин и Буйрами по сей день привлекают внимание туристов, жаждущих увидеть традиционное арабское общество.</p>

        <h2>Шарджа</h2>

        <p>Недалеко от Дубая расположена некогда входящая в список богатейших городов региона – Шарджа, по праву носящая титул культурной столицей Эмиратов, за обилие театров, музеев и университетов, чем она легко может посоперничать со многими европейскими столицами. Впрочем, город совсем невелик и насчитывает немногим более пятисот тысяч жителей, среди которых лишь малая часть приехавших на отдых туристов.</p>

        <p>Главная достопримечательность и гордость Шарджы - одна из крупнейших на Ближнем Востоке мечеть, ставшая подарком правящего Саудовской Аравией короля Фейсала. Здесь же расположен третий по высоте поющий фонтан, бьющий стометровой струёй прямо из вод лагуны Халида. Сопровождаемый лазерными лучами и великолепной музыкой его представление видно со всей набережной.</p>

        <Photograph album = { Album } index = { 27 } />

        <p>На главной площади города, расположен удивительный ансамбль. Выстроенный по кругу, он объединил Дворец культуры – прекрасный представитель мусульманской архитектуры с монументальной лестницей и затейливой каменной кладкой, здания государственного театра и библиотеки, мечеть Ахмада ибн Ханбалы и возвышающийся на семь метров величественный монумент священному Корану.</p>

        <Photograph album = { Album } index = { 29 } />
        <Photograph album = { Album } index = { 30 } />

        <h2>Аджаман</h2>

        <p>Когда-то славящийся своими прекрасными жемчугами, по соседству с Шарджой и Дубаем, раскинулся самый крошечный эмират, Аджаман. Лишённый нефти он не добился того же экономического успеха, что и его соседи. Обречённый на судьбу младшего брата, он пошёл своим путём, став тихим уголком с присущим ему традиционным укладом жизни, где коренное население из поколения в поколение занимается рыбной ловлей и кораблестроением.</p>

        <Photograph album = { Album } index = { 43 } />
        <Photograph album = { Album } index = { 44 } />
        <Photograph album = { Album } index = { 45 } />

        <p>Аджаман это удивительное место, сочетающее в себе настоящее и прошлое. Где можно найти современные роскошные гостиницы и скромные жилища местных жителей, торговые центры с витринами известных мировых брендов и поражающие своим богатством рынки. Нетронутая рукою человека природа и проводимые еженедельно верблюжьи бега – все они становятся воплощением мечты об уюте и традиционном восточном гостеприимстве.</p>

        <Photograph album = { Album } index = { 46 } />

        <h2>Возвращение</h2>

        <p>Время поспешно уходило вслед за заходящим за горизонт солнцем. Оставались короткие минуты перед посадкой на самолёт и возвращением в холодную Россию. Багаж был бережно сдан на автоматических приёмниках, прошла процедура проверки документов и единственное, что все так же сильно радовало глаза, это прекрасный пейзаж, на город, который нисколько не хотелось покидать.</p>

        <Photograph album = { Album } index = { 108 } />

        <p>Побывав за этот год во многих городах, лишь здесь удалось найти интересную и по настоящему восхищающую архитектуру, прибранные улицы, где не разбросан изрядно надоевший мусор, так въевшийся в сознание от жизни в России, познакомиться с огромным количеством людей, среди которых были как туристы, так и живущие с рождения, и вкусившие аромат райской жизни, арабы.</p>

        <p>Что прежде всего производило впечатление, так это местное гостеприимство и чистое доверие, среди которого не было никакого намёка на проросшее с корнями российское желание заполучить свой кусок от чужого пирога. Такими, безусловно наилучшими, останутся навсегда воспоминания об Эмиратах, стране куда хочется вернуться.</p>
      </div>
    </Layout>
  );
}

export default RussianEmirates;