import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const MilitaryMuseum = new Album(
  new Text('Battle Glory of the Urals'),
  Genre.Event,
  new Date('2014-12-25'),
  new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_002_large.webp'),
  [
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_001_large.webp', false, new Text('The Sukhoi Su-24 is a supersonic, all-weather attack aircraft. The aircraft has a variable-sweep wing, twin-engines, and a side-by-side seating arrangement for its crew of two. It was the first of the USSR\'s aircraft to carry an integrated digital navigation/attack system.\n\nThe Mikoyan MiG-31 is a supersonic interceptor aircraft that was developed for use by the Soviet Air Forces. The aircraft was designed by the Mikoyan design bureau as a replacement for the earlier MiG-25 "Foxbat". The MiG-31 is among the fastest combat jets in the world.\n\nThe Sukhoi Su-17 is a variable-sweep wing fighter-bomber developed for the Soviet military. Developed from the Sukhoi Su-7, the Su-17 was the first variable-sweep wing aircraft to enter Soviet service. Two subsequent Sukhoi aircraft, the Su-20, and Su-22 have usually been regarded as variants of the Su-17.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_002_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_003_large.webp', false, new Text('The Yakovlev Yak-9 was a single-engine single-seat multi-purpose fighter aircraft used by the Soviet Union in World War II and after. The Yak-9 started arriving in Soviet fighter aviation regiments in late 1942 and played a major role in retaking air superiority from the Luftwaffe\'s new Focke-Wulf Fw 190 and Messerschmitt Bf 109G fighters during the grand Battle of Kursk in summer 1943.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_004_large.webp', false, new Text('The T-54 and T-55 tanks are a series of Soviet main battle tanks introduced in the years following the Second World War. The first T-54 prototype was completed at Nizhny Tagil by the end of 1945. From the late 1950s, the T-54 eventually became the main tank for armoured units of the Soviet Army, armies of the Warsaw Pact countries, and many others. T-54s and T-55s have been involved in many of the world\'s armed conflicts since their introduction in the last half of the 20th century.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_005_large.webp', false, new Text('The Polikarpov R-5 was a Soviet reconnaissance bomber aircraft of the 1930s. It was the standard light bomber and reconnaissance aircraft of the Soviet Air Force for much of the 1930s, while also being used heavily as a civilian light transport, some 7,000 being built in total.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_006_large.webp', false, new Text('The T-18 light tank was the first Soviet-designed tank. Produced from 1928 to 1931, it was based on the Renault FT, with the addition of a vertically sprung suspension. The T-18 and its derivatives were essentially unsuccessful designs, but they gave Soviet industry its first experiences in designing armored vehicles.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_007_large.webp', false, new Text('The IS-3 is a Soviet heavy tank developed in late 1944. Its semi-hemispherical cast turret became the hallmark of post-war Soviet tanks.\n\nThe IS-2 is the first of the IS tank series named after the Soviet leader Joseph Stalin. It was developed and saw combat during World War II, and saw service in other Soviet allied countries after the war.\n\nThe KV-2 is a heavy assault tank with the M-10 152 mm howitzer. Few were produced due to its combat ineffectiveness, mainly the decreased speed due to the weight of the new gun and turret.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_008_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_009_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_010_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_011_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_012_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_013_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_014_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_015_large.webp', false, new Text('The BM-13H "Katyusha" multiple rocket launcher is a type of rocket artillery first built and fielded by the Soviet Union in World War II. Multiple rocket launchers such as these deliver explosives to a target area more intensively than conventional artillery.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_016_large.webp', false, new Text('The 9K52 Luna-M is a Soviet short-range artillery rocket system that fires unguided and spin-stabilized 9M21 rockets. It was originally developed in the 1960s to provide divisional artillery support using tactical nuclear weapons but gradually modified for conventional use. The 9K52 was eventually succeeded by the OTR-21 Tochka.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_017_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_018_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_019_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_020_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_021_large.webp', false, new Text('The S-75 is a Soviet-designed, high-altitude air defence system, built around a surface-to-air missile with command guidance. Following its first deployment in 1957, it became one of the most widely deployed air defence systems in history. It scored the first destruction of an enemy aircraft by a surface-to-air missile, with the shooting down of a Taiwanese Martin RB-57D Canberra over China on 7 October 1959 that was hit by a salvo of three V-750 (1D) missiles at an altitude of 20 km (65,600 ft). This success was credited to Chinese fighter aircraft at the time to keep the S-75 program secret.\n\nThe 2K11 Krug is a Soviet and now Russian medium-range, medium-to-high altitude surface-to-air missile (SAM) system. The system was designed by NPO Novator and produced by Kalinin Machine Building Plant. Its GRAU designation is "2K11."\n\nThe Buk missile is a family of self-propelled, medium-range surface-to-air missile systems designed to counter cruise missiles, smart bombs, fixed- and rotary-wing aircraft, and unmanned aerial vehicles. The Buk missile system is the successor to the NIIP/Vympel 2K12 Kub. The first version of Buk adopted into service carried the GRAU designation 9K37 Buk and was identified in the west with the NATO reporting name "Gadfly" as well as the US Department of Defense designation SA-11.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_022_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_023_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_024_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_025_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_026_large.webp', false, new Text('The Mil Mi-24 is a large helicopter gunship, attack helicopter, and low-capacity troop transport with room for eight passengers. It is produced by Mil Moscow Helicopter Plant and has been operated since 1972 by the Soviet Air Force and its successors, along with 48 other nations.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_027_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_028_large.webp', false, new Text('The 3–15–4 is an anti-aircraft searchlight station based on the ZiS-5. The station was used extensively in defense against nighttime bomber raids during the Second World War. Searchlights could track bombers, indicating targets to anti-aircraft guns and night fighters and dazzling crews.')),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_029_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_030_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_031_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_032_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_033_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_034_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_035_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_036_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_037_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_038_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_039_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_040_large.webp', true),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_041_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_042_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_043_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_044_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_045_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_046_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_047_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_048_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_049_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_050_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_051_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_052_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_053_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_054_large.webp'),
    new Photograph('/photographs/2014-12-21_25_museum/2014-12-21_25_055_large.webp'),
  ]);

export default MilitaryMuseum;