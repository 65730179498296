import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const ConqueringRome = new Album(
  new Text('Conquering Rome and Order of Malta'),
  Genre.Street,
  new Date('2018-01-05'),
  new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_022_large.webp', false,
    new Text('St. Peter\'s Basilica\n\nThe Papal Basilica of Saint Peter in the Vatican, or simply Saint Peter\'s Basilica, is a church built in the Renaissance style located in Vatican City. It was initially planned by Pope Nicholas V and then Pope Julius II to replace the aging Old St. Peter\'s Basilica, which was built in the fourth century by Roman emperor Constantine the Great. Construction of the present basilica began on 18 April 1506 and was completed on 18 November 1626.\n\nDesigned principally by Donato Bramante, Michelangelo, Carlo Maderno and Gian Lorenzo Bernini, St. Peter\'s is the most renowned work of Renaissance architecture and the largest church in the world by interior measure. While it is neither the mother church of the Catholic Church nor the cathedral of the Diocese of Rome, St. Peter\'s is regarded as one of the holiest Catholic shrines. It has been described as "holding a unique position in the Christian world" and as "the greatest of all churches of Christendom."')),
  [
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_001_large.webp', true,
      new Text('Bramante Staircase\n\nThe modern double helix staircase, also in the Pio-Clementine Museum, and commonly referred to as the "Bramante Staircase", was designed by Giuseppe Momo, sculpted by Antonio Maraini and realized by the Ferdinando Marinelli Artistic Foundry in 1932 and was inspired by the original Bramante Staircase. This staircase, like the original, is a double helix, having two staircases allowing people to ascend without meeting people descending; as with the original, the main purpose of this design is to allow uninterrupted traffic in each direction. It encircles the outer wall of a stairwell approximately fifteen meters wide and with a clear space at the centre. The balustrade around the ramp is of ornately worked metal. A canopy located above provides the necessary light to illuminate the stairs. The staircase is located at the end of the museum visit and all visitors leave by this route.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_002_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_003_large.webp', false,
      new Text('Saint Peter\'s Square\n\nSaint Peter\'s Square is a large plaza located directly in front of St. Peter\'s Basilica in Vatican City. Both the square and the basilica are named after Saint Peter, an apostle of Jesus considered by Catholics to be the first Pope.\n\nAt the centre of the square is an ancient Egyptian obelisk, erected at the current site in 1586. Gian Lorenzo Bernini designed the square almost 100 years later, including the massive Doric colonnades, four columns deep, which embrace visitors in "the maternal arms of Mother Church". A granite fountain constructed by Bernini in 1675 matches another fountain designed by Carlo Maderno in 1613.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_004_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_005_large.webp', false,
      new Text('Pompeii was an ancient city located in what is now the comune of Pompei near Naples. Pompeii, along with Herculaneum and many villas in the surrounding area, was buried under 4 to 6 m of volcanic ash and pumice in the eruption of Mount Vesuvius in AD 79.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_006_large.webp', false,
      new Text('The eruption lasted for two days. The first phase was of pumice rain lasting about 18 hours, allowing most inhabitants to escape. Only approximately 1,150 bodies have so far been found on site seems to confirm this theory and most escapees probably managed to salvage some of their most valuable belongings; many skeletons were found with jewellery, coins and silverware.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_007_large.webp', false,
      new Text('Owing to its wealth and its Greek, Etruscan and Roman history, Pompeii is of great interest for the study of Roman architecture in terms of building methods and urban planning. However, it was a relatively small provincial city and, except for the amphitheatre, it did not have large monuments on the scale of other Roman cities. The smallish size of the town\'s architecture is also because it missed the big building schemes of the early Empire and kept much of its urban architecture that dates from as early as the 4th century BC.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_008_large.webp', true,
      new Text('Modern archaeologists have excavated garden sites and urban domains to reveal the agricultural staples of Pompeii\'s economy. Pompeii was fortunate to have had fertile soil for crop cultivation. The soils surrounding Mount Vesuvius preceding its eruption have been revealed to have had good water-retention capabilities, implying productive agriculture. The Tyrrhenian Sea\'s airflow provided hydration to the soil despite the hot, dry climate. Barley, wheat, and millet were all produced along with wine and olive oil, in abundance for export to other regions.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_009_large.webp', false,
      new Text('The "Large Palaestra" consists of a large open air square, approximately 140 x 140 m, surrounded by porticoes and enclosed by a high wall with battlements in which there are 10 doors. All that is left of the centuries-old plane trees that were on the three sides of the great courtyard at the time of the eruption are moulds of the roots and there lies a 23 x 35 m pool at the centre. It was built in the Augustan period, at the beginning of the 1st century AD, and was intended for the physical and intellectual training of young citizens. Many erotic or poetic graffiti have been left by visitors on the walls and columns. Numerous victims were found during the excavation who had looked for a refuge or a way to escape through the building in vain.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_010_large.webp', false,
      new Text('Evidence of wine imported nationally from Pompeii in its most prosperous years can be found from recovered artefacts such as wine bottles in Rome. For this reason, vineyards were of utmost importance to Pompeii\'s economy. Agricultural policymaker Columella suggested that each vineyard in Rome produce a quota of three cullei of wine per jugerum; otherwise, the vineyard would be uprooted. The nutrient-rich lands near Pompeii were extremely efficient and often capable of largely exceeding these requirements, providing the incentive for local wineries to establish themselves. While wine was exported for Pompeii\'s economy, most other agricultural goods were likely produced in quantities sufficient for the city\'s consumption.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_011_large.webp', false,
    new Text('The evolution of Pompeii\'s private and public buildings is often unclear because of the lack of excavations beneath the levels of 79. It is, however, clear that by the time of the conquest by Sulla in 89 BC, the development of the street layout was largely complete, and most of the insulae were built up.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_012_large.webp', false,
    new Text('Statue of Centaur\n\nBorn in 1944, Igor Mitoraj studied at the Academy of Fine Arts in Kraków, southern Poland, under the legendary artist and theatre director Tadeusz Kantor. He continued his studies in Paris and ultimately settled in Pietrasanta, north western Italy. Besides the French capital, he also held exhibitions in New York, Florence and Lausanne, among others.\n\nMitoraj\'s sculptures typically referenced Antiquity. His art divided critics, with some dismissing his work as kitsch, while others were delighted by his work.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_013_large.webp', false,
      new Text('Statue of Daedalus\n\nThe sculpture of the Greek mythological craftsman Daedalus is part of an exhibition of 30 of Mitoraj’s works on show in the ancient Roman city until 1 May, having been extended by four months. It has been placed at the entrance to the remnants of the ancient city, which was destroyed by the eruption of the volcanic Mount Vesuvius in 79 AD.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_014_large.webp', false,
      new Text('Castel Sant\'Elmo\n\nCastel Sant\'Elmo is a medieval fortress located on Vomero Hill adjacent to the Certosa di San Martino, overlooking Naples. The name "Sant\'Elmo" derives from a former 10th-century church dedicated to Sant\'Erasmo, shortened to "Ermo" and, finally altered to "Elmo". Located near the upper terminus of the Petraio, one of the city\'s earliest pedestrian connections between upper and lower Naples, the fortress now serves as a museum, exhibition hall, and offices.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_015_large.webp', false,
      new Text('Naples\n\nFounded by Greeks in the first millennium BC, Naples is one of the oldest continuously inhabited urban areas in the world. In the eighth century BC, a colony known as Parthenope was established on the Pizzofalcone hill. In the sixth century BC, it was refounded as Neápolis. The city was an important part of Magna Graecia, played a major role in the merging of Greek and Roman society, and was a significant cultural centre under the Romans.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_016_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_017_large.webp', false,
      new Text('Dante Metro Station')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_018_large.webp', true,
      new Text('Mount Vesuvius\n\nMount Vesuvius is a somma-stratovolcano located on the Gulf of Naples in Campania, Italy, about 9 km east of Naples and a short distance from the shore. It is one of several volcanoes forming the Campanian volcanromeic arc. Vesuvius consists of a large cone partially encircled by the steep rim of a summit caldera, resulting from the collapse of an earlier, much higher structure.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_019_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_020_large.webp', true,
      new Text('Vesuvius has a long historic and literary tradition. It was considered a divinity of the Genius type at the time of the eruption of AD 79: it appears under the inscribed name Vesuvius as a serpent in the decorative frescos of many lararia, or household shrines, surviving from Pompeii. An inscription from Capua to IOVI VESVVIO indicates that he was worshipped as a power of Jupiter; that is, Jupiter Vesuvius.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_021_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_022_large.webp', false,
      new Text('St. Peter\'s Basilica\n\nThe Papal Basilica of Saint Peter in the Vatican, or simply Saint Peter\'s Basilica, is a church built in the Renaissance style located in Vatican City. It was initially planned by Pope Nicholas V and then Pope Julius II to replace the aging Old St. Peter\'s Basilica, which was built in the fourth century by Roman emperor Constantine the Great. Construction of the present basilica began on 18 April 1506 and was completed on 18 November 1626.\n\nDesigned principally by Donato Bramante, Michelangelo, Carlo Maderno and Gian Lorenzo Bernini, St. Peter\'s is the most renowned work of Renaissance architecture and the largest church in the world by interior measure. While it is neither the mother church of the Catholic Church nor the cathedral of the Diocese of Rome, St. Peter\'s is regarded as one of the holiest Catholic shrines. It has been described as "holding a unique position in the Christian world" and as "the greatest of all churches of Christendom."')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_023_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_024_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_025_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_026_large.webp', true,
      new Text('Flavian Amphitheatre\n\nThe Colosseum is an oval amphitheatre. It is the largest ancient amphitheatre ever built, and is still the largest standing amphitheatre in the world today, despite its age. Construction began under the emperor Vespasian in 72 and was completed in 80 AD under his successor and heir, Titus. Further modifications were made during the reign of Domitian. The three emperors that were patrons of the work are known as the Flavian dynasty, and the amphitheatre was named the Flavian Amphitheatre by later classicists and archaeologists for its association with their family name.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_027_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_028_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_029_large.webp', false,
      new Text('Roman Forum\n\nThe Roman Forum, also known by its Latin name Forum Romanum, is a rectangular forum (plaza) surrounded by the ruins of several important ancient government buildings at the center of the city of Rome. Citizens of the ancient city referred to this space, originally a marketplace, as the Forum Magnum, or simply the Forum.\n\nFor centuries the Forum was the center of day-to-day life in Rome: the site of triumphal processions and elections; the venue for public speeches, criminal trials, and gladiatorial matches; and the nucleus of commercial affairs. Here statues and monuments commemorated the city\'s great men. The teeming heart of ancient Rome, it has been called the most celebrated meeting place in the world, and in all history. Located in the small valley between the Palatine and Capitoline Hills, the Forum today is a sprawling ruin of architectural fragments and intermittent archaeological excavations attracting 4.5 million or more sightseers yearly.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_030_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_031_large.webp', false,
      new Text('View of the Arch of Constantine with the Colosseum\n\nThe Arch of Constantine is a triumphal arch in Rome dedicated to the emperor Constantine the Great. The arch was commissioned by the Roman Senate to commemorate Constantine\'s victory over Maxentius at the Battle of Milvian Bridge in AD 312. Situated between the Colosseum and the Palatine Hill, the arch spans the Via Triumphalis, the route taken by victorious military leaders when they entered the city in a triumphal procession. Dedicated in 315, it is the largest Roman triumphal arch, with overall dimensions of 21 m high, 25.9 m wide, and 7.4 m deep. It has three bays, the central one being 11.5 m high and 6.5 m wide and the laterals 7.4 m by 3.4 m each. The arch is constructed of brick-faced concrete covered in marble.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_032_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_033_large.webp', true,
      new Text('Valletta\n\nValletta\'s 16th-century buildings were constructed by the Knights Hospitaller. The city was named after Jean Parisot de Valette, who succeeded in defending the island from an Ottoman invasion during the Great Siege of Malta. The city is Baroque in character, with elements of Mannerist, Neo-Classical and Modern architecture, though the Second World War left major scars on the city, particularly the destruction of the Royal Opera House. The city was officially recognised as a World Heritage Site by UNESCO in 1980. Today, with 320 monuments, is one of the most dense monuments areas in the world. Sometimes called an "open-air museum", Valletta was chosen as the European Capital of Culture in 2018. Valletta is also the sunniest city in Europe.\n\nThe city is noted for its fortifications, consisting of bastions, curtains and cavaliers, along with the beauty of its Baroque palaces, gardens and churches.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_034_large.webp', true,
      new Text('Blue Grotto\n\nThe Blue Grotto refers to a number of sea caverns on the south east coast of Malta, a short distance from the fishing harbour limits of Wied iż-Żurrieq. The location of the caves lead to the seawater reflecting shades of blue on the cave walls and ceilings.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_035_large.webp', false,
      new Text('Ħaġar Qim\n\nĦaġar Qim is a megalithic temple complex found on the Mediterranean island of Malta, dating from the Ġgantija phase (3600–3200 BC). The Megalithic Temples of Malta are among the most ancient religious sites on Earth, described by the World Heritage Sites committee as "unique architectural masterpieces." In 1992 UNESCO recognized Ħaġar Qim and four other Maltese megalithic structures as World Heritage Sites. V. Gordon Childe, Professor of Prehistoric European Archeology visited Ħaġar Qim. He wrote, "I have been visiting the prehistoric ruins all round the Mediterranean, from Mesopotamia to Egypt, Greece and Switzerland, but I have nowhere seen a place as old as this one."\n\nĦaġar Qim\'s builders used globigerina limestone in the temple\'s construction. As a result of this, the temple has suffered from severe weathering and surface flaking over the millennia. In 2009 work was completed on a protective tent.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_036_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_037_large.webp', false,
      new Text('Sciuta Tower\n\nSciutu Tower was built in 1637–1638 in Wied iż-Żurrieq, located within the Qrendi boundaries, on the site of a medieval watch post. It served as the prototype for the De Redin towers, which were built between 1658 and 1659.\n\nAfter the British took over Malta in 1800, Sciutu Tower remained in use and was manned by the Royal Malta Fencible Regiment and later the Royal Malta Fencible Artillery. It was abandoned in 1873 but was manned by the Coast Police once again during World War II. The tower subsequently used as a police station until 2002. An original cannon dating back to the Order\'s rule can still be found on the tower\'s roof.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_038_large.webp', false,
      new Text('Evaporation Ponds off the coast of Gozo\n\nThe word salary comes from the Latin word for salt. The reason for this is unknown; a persistent modern claim that the Roman Legions were sometimes paid in salt is baseless. The word salad literally means "salted", and comes from the ancient Roman practice of salting leaf vegetables.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_039_large.webp', false,
      new Text('Sliema\n\nSliema is a town located on the northeast coast of Malta in the Northern Harbour District. It is a major residential and commercial area and a centre for shopping, bars, dining, and café life. It is also the most densely populated town on the island.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_040_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_041_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_042_large.webp', false,
      new Text('Senglea\n\nSenglea is a fortified city. It is one of the Three Cities in the Grand Harbour area, the other two being Cospicua and Vittoriosa. The city\'s title Città Invicta was given because it managed to resist the Ottoman invasion at the Great Siege of Malta in 1565. The name Senglea comes from the Grand Master who built it Claude de la Sengle and gave the city a part of his name. While Senglea is the 52nd most populated locality on the island, due to its incredibly small land area, it is the 2nd most densely populated locality after Sliema.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_043_large.webp', true,
      new Text('Collegiate Church of Saint Lawrence\n\nWhen the Order of Saint John first settled in Malta in 1530, all of their langues were based in Birgu, so the Church of Saint Lawrence was used as the Order\'s first conventual church in Malta. It served this purpose for 41 years from 1530 to 1571 until the Knights were transferred to the new capital city Valletta. The foundation stone of the present church was laid in May 1681 by Bishop Molina. It was completed in 1696. The church was inaugurated by Bishop Davide Cocco Palmieri on the feast of St Lawrence, August 10, 1697. It was consecrated in 1723. In 1820 Pope Pius VII bestowed the dignity of Collegiate church upon the parish church of St Lawrence.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_044_large.webp', true,
      new Text('Rotunda of Mosta\n\nThe Sanctuary Basilica of the Assumption of Our Lady commonly known as the Rotunda of Mosta is a Roman Catholic parish church and Minor Basilica in Mosta, dedicated to the Assumption of Mary. It was built between 1833 and the 1860s to neoclassical designs of Giorgio Grognet de Vassé, on the site of an earlier Renaissance church which had been built in around 1614 to designs of Tommaso Dingli.\n\nThe design of the present church is based on the Pantheon in Rome, has the third largest unsupported dome in the world and is Malta\'s largest and most famous church. The church narrowly avoided destruction during World War II when on 9 April 1942 a German aerial bomb pierced the dome and fell into the church during Mass, but failed to explode. This event was interpreted by the Maltese as a miracle.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_045_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_046_large.webp', true,
      new Text('Mdina\n\nMdina also known by its Italian-language titles Città Vecchia ("Old City") and Città Notabile ("Notable City"), is a fortified city in the Northern Region of Malta which served as the island\'s capital from antiquity to the medieval period. The city is still confined within its walls, and has a population of just under 300, but it is contiguous with the town of Rabat, which takes its name from the Arabic word for suburb, and has a population of over 11,000.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_047_large.webp', true,
      new Text('Verdala Palace\n\nVerdala Palace is a palace in the Buskett Gardens, limits of Siġġiewi. It was built in 1586 during the reign of Hugues Loubenx de Verdalle, and it now serves as the official summer residence of the President of Malta.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_048_large.webp', false,
      new Text('The radar station il Ballun\' near the Dingli Cliffs.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_049_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_050_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_051_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_052_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_053_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_054_large.webp', false,
      new Text('New Year\'s morning in Mellieħa\n\nMellieħa was first inhabited in around 3000 BC, during the Neolithic period. Several megalithic remains have been found, including the temple of Għajn Żejtuna, as well as several caves and tombs, in which tools and pottery fragments were found.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_055_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_056_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_057_large.webp', false,
      new Text('Gozo\n\nThe island is rural in character and less developed than the island of Malta. Gozo is known for its scenic hills, which are featured on its coat of arms. The Azure Window, a natural limestone arch, was a remarkable geological feature until its collapse on March 8, 2017. The island has other notable natural features, including the Inland Sea and Wied il-Mielaħ Window. There are few sandy beaches on the island, all small, as well as seaside resorts that are popular with both locals and tourists, the most popular being Marsalforn and Xlendi. Gozo is considered one of the top diving destinations in the Mediterranean and a centre for water sports.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_058_large.webp', false,
      new Text('Coral Lagoon\n\nThis natural attraction is a large sea cave with a collapsed roof, forming an oval-shaped rocky hole with a narrow entrance to the Mediterranean Sea. Inside the cave, travelers will discover crystal clear waters, in a calm, protected lagoon. This is a great spot to swim, or simply sit and relax away from the busy crowds of neighboring Armier Bay Beach and Mellieħa Bay. This is one of the most breathtaking locations on Malta’s northern shore, and once you see the aerial top view, you’ll find out why this is a popular Instagram and photography location.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_059_large.webp', false,
      new Text('Coral Lagoon')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_060_large.webp', false,
      new Text('Gozo\n\nGozo and in antiquity known as Gaulos is an island in the Maltese archipelago in the Mediterranean Sea. The island is part of the Republic of Malta. After the island of Malta itself, it is the second-largest island in the archipelago.\n\nAs of 2021, the island has a population of around 31,232 (out of Malta\'s total 443,227), and its inhabitants are known as Gozitans. It is rich in historic locations such as the Ġgantija temples, which, along with the other Megalithic Temples of Malta, are amongst the world\'s oldest free-standing structures.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_061_large.webp', false,
      new Text('Mġarr Harbour\n\nThe town of Mġarr grew up around the shallow harbour which shares its name. Ferry services to Malta were in operation by 1241, and fishing was already established. Whilst the area around the harbour was developed over the following centuries, there was little development of the harbour itself until 1841, when a breakwater was constructed to provide more shelter to the port. This breakwater was strengthened and extended several times up to 1906. A larger breakwater was constructed between 1929 and 1935, and two more in 1969; on the completion of the latter, the area of the port was expanded to 121,400 square metres.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_062_large.webp', false,
      new Text('The ferry terminal was rebuilt at a cost of €9.3 million in the early 21st century. Work began in 2001 and took seven years, with the terminal opening in February 2008. The harbour now has facilities for around 600 passengers and 200 cars. The design of the new harbour was changed during the construction process to reduce its visual impact on the surrounding landscape.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_063_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_064_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_065_large.webp', false,
      new Text('The city was founded as Maleth in around the 8th century BC by Phoenician settlers, and was later renamed Melite by the Romans. Ancient Melite was larger than present-day Mdina, and it was reduced to its present size during the Byzantine or Arab occupation of Malta. During the latter period, the city adopted its present name, which derives from the Arabic word medina. The city remained the capital of Malta throughout the Middle Ages, until the arrival of the Order of St. John in 1530, when Birgu became the administrative centre of the island. Mdina experienced a period of decline over the following centuries, although it saw a revival in the early 18th century. At this point, it acquired several Baroque features, although it did not lose its medieval character.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_066_large.webp', false,
      new Text('Mdina remained the centre of the Maltese nobility and religious authorities (and property continues to largely be passed down from families and from generation to generation), but it never regained its pre-1530 importance, giving rise to the popular nickname the "Silent City" by both locals and visitors. Mdina is on the tentative list of UNESCO World Heritage Sites, and it is now one of the main tourist attractions in Malta.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_067_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_068_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_069_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_070_large.webp', true,
      new Text('St Paul\'s Cathedral\n\nThe Metropolitan Cathedral of Saint Paul is a Roman Catholic cathedral in Mdina, dedicated to St. Paul the Apostle. The cathedral was founded in the 12th century, and according to tradition it stands on the site of where Roman governor Publius met St. Paul following his shipwreck on Malta. The original cathedral was severely damaged in the 1693 Sicily earthquake, so it was dismantled and rebuilt in the Baroque style to a design of the Maltese architect Lorenzo Gafà between 1696 and 1705. The cathedral is regarded as Gafà\'s masterpiece.\n\nThe cathedral is the seat of the Roman Catholic Archdiocese of Malta, and since the 19th century this function has been shared with St. John\'s Co-Cathedral in Valletta.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_071_large.webp', false,
      new Text('Ta\' Pinu\n\nThe Basilica of the National Shrine of the Blessed Virgin of Ta\' Pinu is a Roman Catholic minor basilica and national shrine located some 700 metres from the village of Għarb on the island of Gozo.\n\nPope Pius XI granted a Pontifical decree of coronation, “Marianum exstat Sanctuarium” towards its venerated Marian image, the Blessed Virgin of Ta\' Pinu on 24 May 1935. The Archbishop of Tarsus, Cardinal Alexis Lépicier signed and notarized the decree via the Sacred Congregation of Rites. The coronation of the image took place on 20 June 1935.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_072_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_073_large.webp', false,
      new Text('Wied il-Mielaħ Window\n\nThe Wied il-Mielaħ Window is a limestone natural arch on the north-western coast of the island of Gozo. It is located at the end of the valley Wied il-Mielaħ north of the village of Għarb. This natural arch is less well-known than the Azure Window, which collapsed in March 2017 following a heavy storm.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_074_large.webp', false,
    new Text('Rocky Bay')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_075_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_076_large.webp', true,
      new Text('Azure Window\n\nThe Azure Windo also known as the Dwejra Window , was a 28-metre-tall natural arch on the island of Gozo. The limestone feature, which was in Dwejra Bay close to the Inland Sea and Fungus Rock, was one of the island\'s major tourist attractions until it collapsed in stormy weather on 8 March 2017. The arch, together with other natural features in the area, has appeared in a number of international films and media productions.\n\nThe rock formation, which consisted of a pillar rising from the sea joined to the cliff by a horizontal slab, was created by the collapse of a sea cave, probably during the 19th century. The final collapse followed a century of natural erosion, during which large sections of the limestone arch had broken off and fallen into the sea.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_077_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_078_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_079_large.webp', false,
      new Text('Tal-Mixta Cave\n\nTal-Mixta Cave is a picturesque natural cave high up in the hills of Nadur, a small village located in the eastern part of Gozo. The location of this cave, overlooking the idyllic bay of Ramla l-Hamra on the ridge opposite the popular Calypso Cave, offers one of the most stunning views.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_080_large.webp', false,
      new Text('Ramla Bay\n\nRamla Bay is a bay with a beach of reddish-coloured sand in Gozo. It lies on the north-east coast of the island, between the bays of Marsalforn and San Blas.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_081_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_082_large.webp', false,
      new Text('Xlendi\n\nXlendi is an urban village in Malta situated in the south west of the island of Gozo. It is surrounded by the villages of Munxar, Fontana and Kerċem. The village is administered by Munxar, but has its own coat of arms and motto. From March 2010, Xlendi has had its own 5-person "mini council" responsible for the main activities of the area.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_083_large.webp', false,
      new Text('The name Xlendi is of Byzantine origin as it is named after a galley of the period, that was wrecked along the coast, that was called Shilandi. Evidence of this was retrieved near the entry of the bay, at the bottom of the sea, in the 1960s. Since then the site has become a popular diving site.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_084_large.webp', false,
      new Text('Xlendi Tower\n\nThe Xlendi Tower guarding the mouth of the bay was built by the Grandmaster Juan de Lascaris-Castellar on 29 June 1650. This was built so pirates or Turks could not launch attacks from this bay. This tower is still standing. It has, until recently, been abandoned with substantial damage caused to the outer walls of the tower. Responsibility for the tower was passed to the Local Council and Din l-Art Ħelwa in 2010. As of August 2020, restoration works were commenced on its outer structure. The Tower was quite important for the British Army in Malta as it was the only tower in the southwest of the island. It was entitled Tower B (secondly in place) to show its importance.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_085_large.webp', true,
      new Text('Xlendi Bay\n\nDuring the British rule, Xlendi\'s bay was sandy, but with the passage of time, water from the valley and human interference, it is now pebbly. The bay is still known for the rocks on the left side of the bay which are good for sunbathing and diving.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_086_large.webp', true,
      new Text('Narrow Alley In Valletta, Malta')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_087_large.webp', true,
      new Text('Chapel of Our Lady of Philermos at Saint John\'s Co-Cathedral\n\nChapel of Our Lady of Philermos, also known as the Chapel of the Blessed Sacrament – originally contained an icon of Our Lady of Philermos, which had been in the possession of the Order since the Crusades. The icon was taken to Russia by Grand Master Ferdinand von Hompesch zu Bolheim when the Order was expelled from Malta in 1798, and it is now found at the National Museum of Montenegro.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_088_large.webp', true),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_089_large.webp'),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_090_large.webp', true,
      new Text('The Blue Mosque in Istanbul is an Ottoman-era historical imperial mosque. A functioning mosque, it also attracts large numbers of tourist visitors. It was constructed between 1609 and 1616 during the rule of Ahmed I. Its Külliye contains Ahmed\'s tomb, a madrasah, and a hospice. Hand-painted blue tiles adorn the mosque’s interior walls, and at night the mosque is bathed in blue as lights frame the mosque’s five main domes, six minarets, and eight secondary domes. It sits next to the Hagia Sophia, the principal mosque of Istanbul until the Blue Mosque\'s construction and another popular tourist site. The Blue Mosque was included in the UNESCO World Heritage Site list in 1985 under the name of "Historic Areas of Istanbul".')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_091_large.webp', true,
      new Text('After the Peace of Zsitvatorok and the crushing loss in the 1603–18 war with Persia, Sultan Ahmed I decided to build a large mosque in Istanbul to reassert Ottoman power. It would be the first imperial mosque for more than forty years. While his predecessors had paid for their mosques with the spoils of war, Ahmed I procured funds from the Treasury, because he had not gained remarkable victories. The construction was started in 1609 and completed in 1616.')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_092_large.webp', false,
      new Text('Having been paid from the public treasury rather than from the sultan\'s war booty, as was done normally, it caused the anger of the ulama, the Muslim jurists. The mosque was built on the site of the palace of the Byzantine emperors, in front of the basilica Hagia Sophia (at that time, the primary imperial mosque in Istanbul) and the hippodrome, a site of significant symbolic meaning as it dominated the city skyline from the south. Big parts of the south shore of the mosque rest on the foundations, the vaults of the old Grand Palace.')),
  ]);

export default ConqueringRome;